/**
  NOTA: ALOJAR AQUI SOLO FUNCIONES SCSS.
*/

/**
  Funcion encargada de crear los porcentajes para los
  width, width-min, width-max y height, height-min, height-max
  del 0 al 100. Ejemplo de utilizacion: "fw-min-60", "fh-100".
*/
@for $i from 0 through 100 {
    .fw-#{$i} {
        width: #{$i + '%'} !important;
    }
    .fw-max-#{$i} {
        max-width: #{$i + '%'} !important;
    }
    .fw-min-#{$i} {
        min-width: #{$i + '%'} !important;
    }
    .fh-#{$i} {
        height: #{$i + '%'} !important;
    }
    .fh-max-#{$i} {
        max-height: #{$i + '%'} !important;
    }
    .fh-min-#{$i} {
        min-height: #{$i + '%'} !important;
    }
}

/**
  Funcion encargada de crear los line-heights
  customizados (chequear archivo _variables).
*/
@each $type, $size in $line-heights {
    .lh-#{$type} {
        line-height: #{$size} !important;
    }
}

/**
  Funcion encargada de crear las opacidades
  customizadas (chequear archivo _variables).
*/
@each $opacity, $value in $opacities {
    .opacity-#{$opacity} {
        opacity: #{$value} !important;
    }
}

/**
Funcion encargada de crear los tamaños de
textos customizados (chequear archivo _variables).
*/
@each $num, $size in $text-sizes {
    .text-size-#{$num} {
        font-size: #{$size} !important;
    }
}

/**
  Funcion encargada de crear las gaps
  customizados (chequear archivo _variables).
*/
@each $num, $value in $gaps {
    .gap-#{$num} {
        gap: #{$value} !important;
    }
}

/**
  Funcion encargada de armar las distintas
  clases para tooltip directive (chequear
  archivo _variables).
*/
@each $type, $size in $tooltip-sizes {
    .tooltip-arrow-#{$type} {
        content: '';
        position: absolute;
        width: 0;
        height: 0;

        &-left {
            @extend .tooltip-arrow-#{$type};
            top: calc(50% - #{$size});
            left: -#{$size};
            border-right: #{$size} solid;
            border-top: #{$size} solid transparent;
            border-bottom: #{$size} solid transparent;
        }

        &-right {
            @extend .tooltip-arrow-#{$type};
            top: calc(50% - #{$size});
            right: -#{$size};
            border-left: #{$size} solid;
            border-top: #{$size} solid transparent;
            border-bottom: #{$size} solid transparent;

            &-top {
                @extend .tooltip-arrow-#{$type}-right;
                top: 5%;
            }
        }

        &-bottom {
            @extend .tooltip-arrow-#{$type};
            top: 100%;
            bottom: -#{$size};
            border-left: #{$size} solid transparent;
            border-right: #{$size} solid transparent;
            border-top: #{$size} solid;

            &-left {
                @extend .tooltip-arrow-#{$type}-bottom;
                left: 5%;
            }

            &-center {
                @extend .tooltip-arrow-#{$type}-bottom;
                left: calc(50% - #{$size});
            }

            &-right {
                @extend .tooltip-arrow-#{$type}-bottom;
                right: 5%;
            }
        }

        &-top {
            @extend .tooltip-arrow-#{$type};
            top: -#{$size};
            border-left: #{$size} solid transparent;
            border-right: #{$size} solid transparent;
            border-bottom: #{$size} solid;

            &-left {
                @extend .tooltip-arrow-#{$type}-top;
                left: 5%;
            }

            &-center {
                @extend .tooltip-arrow-#{$type}-top;
                left: calc(50% - #{$size});
            }

            &-right {
                @extend .tooltip-arrow-#{$type}-top;
                right: 5%;
            }

            &-notificationMobile-left {
                @extend .tooltip-arrow-#{$type}-top;
                right: 7.5%;
            }
        }
    }
}
