/**
  NOTA: ALOJAR AQUI SOLO KEYFRAMES.
*/

/**
  Keyframe para la directiva "exchange-variations.directive"
*/
@keyframes bg-lower {
    0% {
        background-color: inherit;
    }

    75% {
        background-color: #dd00001a;
        font-weight: bolder;
    }

    100% {
        background-color: inherit;
    }
}

/**
  Keyframe para la directiva "exchange-variations.directive"
*/
@keyframes bg-upper {
    0% {
        background-color: inherit;
    }

    75% {
        background-color: #3ba98c1a;
        font-weight: bolder;
    }

    100% {
        background-color: inherit;
    }
}
