/**
  NOTA: ALOJAR AQUI SOLO MEDIA QUERIES.
*/

@media only screen and (max-width: 640px) {
    body {
        --width-form-auth: 100% !important;
    }
}
@media (prefers-color-scheme: dark) {
    .appearance-none {
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        appearance: none !important;
    }

    .outline-none {
        outline: none !important;
        box-shadow: none !important;
    }

    .outline-none:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

// Notificaciones modal styels
@media (max-width: 767px) {
    .modal-notificaciones .hidden-xs {
        display: none !important;
    }

    .modal-notificaciones .modal-footer {
        justify-content: flex-end !important;
    }
}

@media (min-width: 768px) {
    .hidden-lg {
        display: none !important;
    }

    .modal-notificaciones .modal-footer {
        align-items: unset !important;
        justify-content: space-between !important;
    }
}

@media (min-width: 1200px) {
    .sticky-header {
        position: sticky !important;
        top: 68px !important;
        z-index: 1029 !important;
        height: 3.625rem !important;
        align-items: center !important;
    }
}
