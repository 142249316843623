/**
  NOTA: ALOJAR AQUI SOLO VARIABLES.
*/

/* Fuerzo line heights (Ej: class="lh-sm") */
$line-heights: (
    'xxs': 0.75,
    'xs': 1,
    'sm': 1.25,
    'md': 1.5,
    'lg': 1.75,
    'xl': 2
);

/* Fuerzo las opacidades. */
$opacities: (
    '1': 0.2,
    '2': 0.4,
    '3': 0.6,
    '4': 0.8
);

/* Fuerzo los tamaños de los textos. */
$text-sizes: (
    1: 0.5rem,
    2: 0.625rem,
    3: 0.688rem,
    4: 0.75rem,
    5: 0.813rem,
    6: 0.875rem,
    6b: 0.925rem,
    7: 1rem,
    8: 1.125rem,
    9: 1.25rem,
    9b: 1.313rem,
    10: 1.375rem,
    11: 1.5rem,
    12: 1.7rem,
    13: 1.9rem,
    14: 2rem,
    15: 2.5rem,
    16: 3rem,
    17: 4rem,
    18: 5rem,
    19: 6rem
);

/* Custom Gaps */
$gaps: (
    0: 0,
    1: 0.25rem,
    2: 0.5rem,
    3: 0.75rem,
    4: 1rem,
    5: 1.25rem,
    6: 1.5rem,
    7: 1.7rem,
    8: 2rem,
    9: 2.5rem
);

/* Tooltip arrows (para componente tooltip.component) */
$tooltip-sizes: (
    'xxs': 5px,
    'xs': 7px,
    'sm': 11px,
    'md': 15px,
    'lg': 19px
);
