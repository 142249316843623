/**
  ACA VA TODAS AQUELLAS CLASES QUE SON DE IMPLEMENTACION
  GLOBAL. SI NO ES ASI, AGREGARLO EN EL SCSS DEL PROPIO
  COMPONENTE DONDE SE UTILICE.
*/

* {
    box-shadow: none !important;
    outline: none !important;
    font-family: 'Open Sans', sans-serif !important;
}

a {
    text-decoration: none !important;
}

button {
    background-color: transparent;
    border: 0;
}
/* solo para el overlay del cdk*/
.cdk-overlay-container {
    z-index: 300000 !important;
}

/* font families */
.font-sans {
    font-family: 'Open Sans', sans-serif !important;
}

/* Overflows */
.overflow-y-scroll {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}
.overflow-x-scroll {
    overflow-y: hidden !important;
    overflow-x: scroll !important;
}
.overflow-x-auto {
    overflow-x: auto !important;
}
.overflow-y-auto {
    overflow-y: auto !important;
}
.overflow-x-hidden {
    overflow-x: hidden !important;
}
.overflow-y-hidden {
    overflow-y: hidden !important;
}

/* Pointer */
.cursor-pointer {
    cursor: pointer !important;
}

/* User select */
.user-select-none {
    user-select: none;
}

/* Custom shadows */
.shadow-caret-right {
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.15)) !important;
}

/* Elimina las arrow de los input number */
.hide-arrow::-webkit-outer-spin-button,
.hide-arrow::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}
.hide-arrow[type='number'] {
    appearance: textfield !important;
    -moz-appearance: textfield !important;
}

/* Custom paddings */
.pt-2r {
    padding-top: 2rem !important;
}
.custom-p-4 {
    padding: 1.875rem !important;
}
.custom-ps-4 {
    padding-left: 1.875rem !important;
}
.custom-pe-4 {
    padding-right: 1.875rem !important;
}
.custom-pt-4 {
    padding-top: 1.875rem !important;
}
.custom-pb-4 {
    padding-bottom: 1.875rem !important;
}
.custom-px-4 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
}
.custom-py-4 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
}

/* Buttons overrides */
.primary-buttons {
    background-color: var(--bg-button-primary) !important;
    border-radius: 6px !important;
    color: var(--text-button-primary) !important;
    border: 0 !important;
}
.primary-buttons:hover,
.primary-buttons:focus {
    background-color: var(--bg-hover-button-primary) !important;
    color: #ffffff;
}
.primary-buttons:active {
    background-color: var(--bg-hover-button-primary) !important;
    color: #ffffff;
    box-shadow: 0px 3px 7px var(--bg-hover-button-primary) !important;
}
.actionButton {
    margin-right: 1rem !important;
    border: 0px !important;
    border-radius: 0.4rem !important;
    padding: 0.4rem 1.25rem !important;
    font-size: 0.8rem !important;
    font-weight: 600 !important;
    background-color: var(--action-button-bg-color) !important;
    color: var(--text-color-primary) !important;
}
.actionButton:hover {
    background-color: var(--action-button-hover-bg-color) !important;
}
.secondary-buttons {
    color: var(--button-outline-color) !important;
    font-size: 0.75rem !important;
    border: 0.063rem solid var(--bg-button-primary) !important;
}
.secondary-buttons:hover,
.secondary-buttons:focus {
    background-color: var(--bg-button-secondary-hover) !important;
    color: var(--button-outline-color) !important;
}
.secondary-buttons:active {
    background-color: var(--bg-button-secondary-hover) !important;
    color: var(--button-outline-color) !important;
    box-shadow: 0rem 0.125rem 0.188rem var(--bg-button-primary) !important;
}
.tertiary-buttons {
    background-color: transparent;
    color: var(--text-color-primary-alt-dark) !important;
    font-size: 0.75rem;
}
.tertiary-buttons:hover,
.tertiary-buttons:focus,
.tertiary-buttons:active {
    color: var(--text-button-tertiary-2) !important;
}
.class-button {
    width: 6.938rem !important;
    height: 1.563rem !important;
    padding: 3px 9px 3px 9px !important;
    border-radius: 2px !important;
    border: solid 0.5px var(--border-class-button) !important;
    border-radius: 3px !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    background-color: var(--bg-class-button) !important;
    font-size: 0.75rem !important;
    margin-top: 5px !important;
    color: var(--text-color-base) !important;
}
.class-button:focus {
    box-shadow: none !important;
}
.button-outline-primary {
    color: var(--button-outline-color) !important;
    border-color: var(--button-outline-color) !important;
}
.primary-buttons:disabled,
.btn-outline-primary:disabled,
.secondary-buttons:disabled {
    pointer-events: none !important;
}
.btn-outline-danger {
    color: var(--color-text-danger) !important;
    border-color: var(--color-text-danger) !important;
}
.btn-outline-success {
    color: var(--color-text-success) !important;
    border-color: var(--color-text-success) !important;
}

.btn-transparent {
    border: 0 !important;
}
/* Select overrides */
.custom-select {
    border: 0.5px solid var(--text-color-base) !important;
    border-radius: 3px !important;
    color: var(--text-color-base) !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background: var(--bg-color-default) !important;
    background-image: url(https://cms.balanz.com/PFS/068411_iconarrowselect.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center right 7px !important;
    padding: 6px 30px 6px 10px !important;
    cursor: pointer !important;
}
select {
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background: transparent !important;
    background-image: url('/assets/ico-chevron-down-select.svg') !important;
    background-repeat: no-repeat !important;
    background-position-x: 100% !important;
    background-position-y: 50% !important;
    padding-right: 1.2rem !important;
}
option {
    background-color: var(--base) !important;
}
.dark-theme select {
    background-image: url('/assets/nextIcon-dark.svg') !important;
}

/* Dropdowns overrides */
.dropdown-select-buttons {
    color: var(--base-contrast) !important;
    background-color: var(--base) !important;
}
.dropdown-select-buttons:active {
    color: var(--base) !important;
    background-color: #6a6a6b !important;
}
.dropdown-select-buttons:focus {
    color: var(--base) !important;
    background-color: #6a6a6b !important;
}
.dropdown-item:active {
    background-color: var(--bg-color-base) !important;
    color: var(--text-color-default) !important;
}
.dropdown-item:hover {
    background-color: var(--bg-color-base) !important;
    color: var(--text-color-default) !important;
}
.dropdown-item {
    color: var(--text-color-base) !important;
}

/* inputs overrides */
input:disabled {
    color: var(--input-disabled) !important;
    -webkit-text-fill-color: var(--input-disabled) !important;
}
input::-ms-reveal,
input::-ms-clear {
    display: none !important;
}
.textarea-border {
    border: solid 1px var(--border-light-gray) !important;
}
.input-custom-checkbox {
    visibility: hidden !important;
}
.custom-checkbox-container {
    border: 1px solid var(--text-color-base) !important;
    width: 15px !important;
    height: 15px !important;
    border-radius: 2px !important;
}
.custom-checkbox-filtro {
    position: absolute !important;
    top: 1px !important;
    left: 1px !important;
    height: 11px !important;
    width: 11px !important;
    border-radius: 1px !important;
}
.option input:active ~ .custom-checkbox-filtro {
    background-color: var(--accent-5) !important;
}
.option input:checked ~ .custom-checkbox-filtro {
    background-color: var(--accent-5) !important;
}

/* textarea overrides */
.bz-textarea,
.bz-textarea:focus {
    border: 1px solid var(--border-form-control) !important;
    background-color: transparent !important;
    resize: none !important;
    box-shadow: none !important;
    outline: none !important;

    &::placeholder {
        color: var(--text-color-placeholder) !important;
    }
}

/* Form controls overrides */
.form-control {
    background-color: transparent !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    padding-left: 0 !important;
    border-bottom: 1px solid var(--border-form-control) !important;
}
.form-control:focus {
    background-color: var(--background-color) !important;
    box-shadow: inset 0 -1px 0 #ddd !important;
}
.form-control option {
    font-size: 0.95rem !important;
}
.form-control input {
    border-bottom: 0.063rem solid var(--bg-color-border) !important;
}
.form-control select {
    border-bottom: 0.063rem solid var(--bg-color-border) !important;
}

.form-control::placeholder {
    color: var(--text-color-base) !important;
}

/* Cards overrides */
.card {
    background-color: var(--bg-color-default) !important;
    color: var(--text-color-default) !important;
}
.card-balanz .card-body {
    padding: 0px !important;
}
.card.card-balanz .card-header {
    background-color: #f0f0f0 !important;
    border-bottom: 0.063rem solid #cccccc !important;
    color: var(--text-color-base) !important;
    height: var(--header-height) !important;
}
.card-header {
    background-color: var(--bg-color-default) !important;
    color: var(--text-color-default) !important;
}
.card-body {
    background-color: var(--bg-color-default) !important;
    color: var(--text-color-default) !important;
}
.card-footer {
    background-color: var(--bg-color-default) !important;
    color: var(--text-color-default) !important;
}

/* Custom text colors */
.text-base {
    color: var(--base) !important;
}
.text-base-contrast {
    color: var(--base-contrast) !important;
}
.text-color-default {
    color: var(--text-color-default) !important;
}
.text-color-base {
    color: var(--text-color-base) !important;
}
.text-color-base-alt-dark {
    color: var(--text-color-base-alt-dark) !important;
}
.text-color-base-light {
    color: var(--base-light) !important;
}
.text-color-primary {
    color: var(--text-color-primary) !important;
}
.text-color-primary-alt-dark {
    color: var(--text-color-primary-alt-dark) !important;
}
.text-color-alternative {
    color: var(--text-color-alternative) !important;
}
.text-info-primary {
    color: var(--text-info-primary) !important;
}
.text-color-muted {
    color: var(--text-color-muted) !important;
}
.text-color-error {
    color: var(--text-color-error) !important;
}
.text-color-light-green {
    color: var(--text-color-light-green) !important;
}
.text-color-dark-red {
    color: var(--text-color-dark-red) !important;
}
.text-color-light-red {
    color: var(--text-color-light-red) !important;
}
.text-color-light-gray {
    color: var(--text-color-light-gray) !important;
}
.text-color-black {
    color: var(--text-color-black) !important;
}
.text-color-menu-tooltip {
    color: var(--text-color-menu-tooltip) !important;
}
.text-color-popover {
    color: var(--color-text-popover) !important;
}
.text-accent-0 {
    color: var(--accent-0) !important;
}
.text-accent-1 {
    color: var(--accent-1) !important;
}
.text-accent-2 {
    color: var(--accent-2) !important;
}
.text-accent-3 {
    color: var(--accent-3) !important;
}
.text-accent-4 {
    color: var(--accent-4) !important;
}
.text-color-blue-cyan {
    color: var(--text-color-blue-cyan) !important;
}
.text-all-white {
    color: var(--all-white) !important;
}
.separator {
    color: #dedede !important;
}
.high-contrast-black {
    color: var(--high-contrast-black) !important;
}
.text-recomendaciones-instrument {
    color: var(--text-recomendaciones-instrument) !important;
}
.color-modal-deposit {
    color: var(--color-modal-deposit) !important;
}
.color-gray-input-2 {
    color: var(--color-gray-input-2) !important;
}

/* Custom backgrounD colors */
.background-color {
    background-color: var(--background-color) !important;
}
.bg-color-default {
    background-color: var(--bg-color-default) !important;
}
.bg-color-base {
    background-color: var(--bg-color-base) !important;
}
.lightblue-bg {
    background-color: var(--lightblue-bg) !important;
}
.bg-color-primary {
    background-color: var(--bg-color-primary) !important;
}
.bg-color-light-gray {
    background-color: var(--bg-color-light-gray) !important;
}
.bg-color-card-preview {
    background-color: var(--bg-color-card-preview) !important;
}
.bg-color-card-icon {
    background-color: var(--bg-color-card-icon) !important;
}
.bg-color-error {
    background-color: var(--bg-color-error) !important;
}
.bg-accent-0 {
    background-color: var(--accent-0) !important;
}
.bg-accent-1 {
    background-color: var(--accent-1) !important;
}
.bg-accent-2 {
    background-color: var(--accent-2) !important;
}
.bg-accent-3 {
    background-color: var(--accent-3) !important;
}
.bg-accent-4 {
    background-color: var(--accent-4) !important;
}
.bg-light-header {
    background-color: rgba(97, 112, 162, 0.06) !important;
}
.menu-bg-color {
    background-color: var(--menu-bg-color) !important;
}
.header-bg-color {
    background-color: var(--header-bg-color) !important;
}
.bg-all-white {
    background-color: var(--all-white) !important;
}
.bg-badget-info {
    background-color: var(--bg-badget-info) !important;
}
.background-light {
    background-color: var(--background-light-color) !important;
}
.bg-color-placeholder {
    background-color: var(--bg-color-placeholder) !important;
}
.bg-tooltip {
    background-color: var(--bg-tooltip) !important;
}

.bg-white-default {
    background-color: var(--bg-color-default) !important;
}

/* Clases para exchange-variations.directive */
.exchange-upper {
    color: var(--color-text-success) !important;
}
.exchange-lower {
    color: var(--color-exchange-lower) !important;
}
.exchange-bg-upper {
    animation: bg-upper 1s cubic-bezier(0.39, 0.575, 0.565, 1) both !important;
    -webkit-animation: bg-upper 1s cubic-bezier(0.39, 0.575, 0.565, 1) both !important;
}
.exchange-bg-lower {
    animation: bg-lower 1s cubic-bezier(0.39, 0.575, 0.565, 1) both !important;
    -webkit-animation: bg-lower 1s cubic-bezier(0.39, 0.575, 0.565, 1) both !important;
}

/* modal-v2 override*/
.modal-body {
    padding: 1.875rem !important;
}

/* Table overrides */
table {
    th {
        font-weight: 600;
    }
}
.table {
    color: var(--text-color-default) !important;
    background-color: var(--bg-color-default) !important;
}
.table tbody tr:hover td:not(.no-hover) {
    color: var(--text-color-default) !important;
    background-color: var(--bg-color-default) !important;
}

.table > :not(caption) > * > * {
    background-color: var(--bg-color-default) !important;
    color: var(--text-color-default) !important;
}
.column-first-fix {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: var(--bg-color-default) !important;
}
.column-last-fix {
    position: sticky;
    right: 0;
    z-index: 1;
    background-color: var(--bg-color-default) !important;
}
.table .no-border tr td {
    border: 0;
}

/* Color de estados */
.a-confirmar {
    font-size: 10px;
    border: 1px solid var(--text-color-primary-alt-dark) !important;
    color: var(--text-color-primary-alt-dark) !important;
    background-color: var(--bg-confirm-order) !important;
    border-radius: 3px !important;
    padding: 2px 9px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 80px !important;
}
.color-cancelada {
    background-color: rgba(79, 79, 79, 0.2) !important;
    color: var(--text-color-alternative) !important;
    max-width: 75px !important;
}
.color-ejecucion-cancelando {
    background-color: rgba(79, 79, 79, 0.2) !important;
    color: var(--text-color-alternative) !important;
    width: 94px !important;
    display: block !important;
    white-space: normal !important;
}
.color-cancelada-por-autorizar {
    background-color: rgba(79, 79, 79, 0.2) !important;
    color: var(--text-color-alternative) !important;
    max-width: 84px !important;
}
.color-ejecutada {
    background-color: rgba(51, 165, 135, 0.2) !important;
    color: #33a587 !important;
    max-width: 70px !important;
}
.color-en-ejecucion {
    color: #33a587 !important;
    border: solid 0.5px #33a587 !important;
    max-width: 75px !important;
}
.color-informado {
    background-color: rgba(96, 113, 162, 0.2) !important;
    color: #6071a2 !important;
    max-width: 70px !important;
}
.color-pendiente {
    background-color: rgba(251, 151, 71, 0.2) !important;
    color: #de7a2b !important;
    max-width: 70px !important;
    font-size: 0.625rem !important;
}
.color-rechazada {
    background-color: rgba(208, 2, 27, 0.2) !important;
    color: #d0021b !important;
    max-width: 70px !important;
}
.color-finalizada {
    background-color: rgba(51, 165, 135, 0.2) !important;
    color: #33a587 !important;
    max-width: 70px !important;
}
.finalizada-cancelando {
    color: #33a587 !important;
    background-color: rgba(51, 165, 135, 0.2) !important;
    max-width: 84px !important;
}
.error-creacion {
    background-color: rgba(208, 2, 27, 0.2) !important;
    color: #d0021b !important;
    max-width: 70px !important;
}
.expirada {
    background-color: rgba(79, 79, 79, 0.2) !important;
    color: var(--text-color-alternative) !important;
    max-width: 70px !important;
}
.pendiente-cancelando {
    background-color: rgba(222, 122, 43, 0.2) !important;
    color: #de7a2b !important;
    max-width: 84px !important;
}
.color-default {
    background-color: rgba(96, 113, 162, 0.2) !important;
    color: #6071a2 !important;
    max-width: 70px !important;
}

/* faqs popover */
.popover {
    background-color: var(--bg-color-default) !important;
    color: var(--text-color-default) !important;
}
.popover .arrow::after,
.bs-popover-bottom .arrow::after {
    border-bottom-color: var(--bg-color-default) !important;
}

/* ng-select component overrides */
.ng-select.custom {
    border-bottom: solid 0.063rem var(--border-form-control) !important;
    color: var(--text-color-primary-alt-dark) !important;
    font-size: 0.75rem !important;
    font-weight: 600 !important;

    .ng-input > input {
        color: var(--text-color-primary-alt-dark) !important;
        width: 85% !important;
        text-overflow: ellipsis !important;
        font-weight: 600 !important;
    }
}
.ng-select.custom .ng-select-container {
    min-height: 0px !important;
    border-radius: 0 !important;
}
.ng-dropdown-panel {
    background-color: var(--base) !important;
    border: solid 1px var(--base-contrast) !important;
    padding: 10px !important;
}
.ng-option {
    color: var(--text-color-primary) !important;
    font-size: 0.75rem !important;
    margin: 0.02rem !important;
    font-weight: 600 !important;
}

/* arrow top styles */
.tooltip-arrow-top {
    &-primary {
        border-bottom-color: var(--bg-tooltip) !important;
    }
    &-secondary {
        border-bottom-color: var(--secondary) !important;
    }
    &-info {
        border-bottom-color: var(--info) !important;
    }
    &-warning {
        border-bottom-color: var(--warning) !important;
    }
    &-danger {
        border-bottom-color: var(--danger) !important;
    }
    &-success {
        border-bottom-color: var(--success) !important;
    }
    &-light {
        border-bottom-color: var(--light) !important;
    }
    &-dark {
        border-bottom-color: var(--dark) !important;
    }
    &-muted {
        border-bottom-color: var(--muted) !important;
    }
}

/* arrow right styles */
.tooltip-arrow-right {
    &-primary {
        border-left-color: var(--bg-tooltip) !important;
    }
    &-secondary {
        border-left-color: var(--secondary) !important;
    }
    &-info {
        border-left-color: var(--info) !important;
    }
    &-warning {
        border-left-color: var(--warning) !important;
    }
    &-danger {
        border-left-color: var(--danger) !important;
    }
    &-success {
        border-left-color: var(--success) !important;
    }
    &-light {
        border-left-color: var(--light) !important;
    }
    &-dark {
        border-left-color: var(--dark) !important;
    }
    &-muted {
        border-left-color: var(--muted) !important;
    }
}

/* arrow left styles */
.tooltip-arrow-left {
    &-primary {
        border-right-color: var(--bg-tooltip) !important;
    }
    &-secondary {
        border-right-color: var(--secondary) !important;
    }
    &-info {
        border-right-color: var(--info) !important;
    }
    &-warning {
        border-right-color: var(--warning) !important;
    }
    &-danger {
        border-right-color: var(--danger) !important;
    }
    &-success {
        border-right-color: var(--success) !important;
    }
    &-light {
        border-right-color: var(--light) !important;
    }
    &-dark {
        border-right-color: var(--dark) !important;
    }
    &-muted {
        border-right-color: var(--muted) !important;
    }
}

/* arrow bottom styles */
.tooltip-arrow-bottom {
    &-primary {
        border-top-color: var(--bg-tooltip) !important;
    }
    &-secondary {
        border-top-color: var(--secondary) !important;
    }
    &-info {
        border-top-color: var(--info) !important;
    }
    &-warning {
        border-top-color: var(--warning) !important;
    }
    &-danger {
        border-top-color: var(--danger) !important;
    }
    &-success {
        border-top-color: var(--success) !important;
    }
    &-light {
        border-top-color: var(--light) !important;
    }
    &-dark {
        border-top-color: var(--dark) !important;
    }
    &-muted {
        border-top-color: var(--muted) !important;
    }
}

/* menu drawer overrides */
.modal-body-padding {
    padding: 1rem 1.875rem 1.875rem 1.875rem !important;
}
.modal-header-padding {
    padding: 1.875rem 1.875rem 1.25rem 1.875rem !important;
}

/* Alerts overrides */
.intntl-custom-alert {
    background-color: var(--balanz-info-bg-color) !important;
    border-radius: 0px !important;
    font-size: 0.75rem !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    letter-spacing: -0.24px !important;
    text-align: left !important;
    line-height: 0.75rem !important;
    color: var(--balanz-info-color) !important;
    stroke: var(--balanz-info-color) !important;
    border: 1px solid var(--balanz-info-border-color) !important;
    stroke-width: 0.2px !important;
}
.dark-alert-success {
    background-color: var(--bg-color-default) !important;
    border: 1px solid var(--balanz-info-color) !important;
}
.dark-alert-danger {
    background-color: var(--bg-color-default) !important;
    color: var(--text-color-error) !important;
    border: 1px solid var(--text-color-error) !important;
}
.alert-success {
    background-color: var(--bg-alert-success) !important;
    color: var(--balanz-info-color) !important;
    border: 1px solid var(--border-alert-success) !important;
    border-radius: 0 !important;
    font-size: 0.75rem !important;
}
.alert-danger {
    color: var(--color-alert-danger) !important;
    background-color: var(--bg-alert-danger) !important;
    border: 1px solid var(--border-alert-danger) !important;
    border-radius: 0 !important;
}
.alert-info {
    color: var(--color-alert-info) !important;
    background-color: var(--bg-alert-info) !important;
    border: 1px solid var(--border-alert-info) !important;
    font-size: 0.75rem !important;
    border-radius: 0 !important;
}
.alert-warning-innerHtml {
    display: inline !important;
}
.alert-warning {
    color: var(--color-alert-warning) !important;
    background-color: var(--bg-alert-warning) !important;
    font-size: 0.75rem !important;
    border: 1px solid var(--border-alert-warning) !important;
    font-size: 0.75rem !important;
    border-radius: 0 !important;
}

/* Borders override */
.border {
    border: 0.063rem solid var(--border-item) !important;
}
.border-left {
    border-left: 0.063rem solid var(--border-item) !important;
}
.border-right {
    border-right: 0.063rem solid var(--border-item) !important;
}
.border-top {
    border-top: 0.063rem solid var(--border-item) !important;
}
.border-bottom {
    border-bottom: 0.063rem solid var(--border-item) !important;
}
.border-bottom-light-gray {
    border-bottom: 0.5px solid var(--border-light-gray) !important;
    width: 75% !important;
    margin: 0 auto !important;
}

/* Pastillas perfil inversor fondos*/
.perfil-base {
    font-size: 0.625rem !important;
    font-weight: 600 !important;
    width: 5.188rem !important;
    height: 1.25rem !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0.313rem 0.938rem !important;
    border-radius: 3px !important;
}
.perfil-Moderado {
    color: var(--text-color-mostaza) !important;
    background-color: var(--bg-color-mostaza) !important;
}
.perfil-Conservador {
    color: var(--text-color-green-lima) !important;
    background-color: var(--bg-color-green-lima) !important;
}
.perfil-Audaz {
    color: var(--text-color-red-2) !important;
    background-color: var(--bg-color-red-2) !important;
}
.perfil-Calificado {
    color: var(--text-color-red3) !important;
    background-color: var(--bg-color-red3) !important;
}
.selector-label {
    color: var(--text-color-placeholder) !important;
    padding-bottom: 0.375rem !important;
}

/* Misc */
.divider {
    width: 100% !important;
    height: 1px !important;
    background: var(--bg-color-border) !important;
}
.sticky-header {
    background: var(--bg-color-default) !important;
}
.dark-img {
    opacity: 0.75;
}
.info-balanz {
    background-color: var(--balanz-info-bg-color) !important;
    border: solid 1px var(--balanz-info-border-color) !important;
    color: var(--balanz-info-color) !important;
    font-size: 0.75rem !important;
    border-radius: 0px !important;
}
.info-balanz img {
    width: 16px !important;
    height: 16px !important;
}
.ajuste-avatar {
    object-fit: cover !important;
}
.badge-new {
    background-color: var(--bg-color-badge-new) !important;
    color: white !important;
    border-radius: 0.25rem 0 0.25rem 0 !important;
    width: fit-content !important;
    padding: 0 0.5rem !important;
}
.btn-actions-elipsis,
.btn-actions-elipsis ul li {
    color: var(--btn-actions) !important;
    background-color: var(--btn-actions-bg) !important;
}
.tooltip-clicked {
    background-color: var(--bg-tooltip-clicked) !important;
}

/* Scrollbar (https://css-tricks.com/custom-scrollbars-in-webkit/) */
::-webkit-scrollbar-button,
::-webkit-scrollbar-corner,
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-corner,
::-webkit-resizer {
    display: none;
    background-color: transparent;
}
::-webkit-scrollbar {
    width: 0.438rem;
    height: 0.438rem;
    background-color: transparent;
    position: absolute;
}
::-webkit-scrollbar-thumb {
    border-radius: 0.313rem;
    box-shadow: inset 0 0 0.375rem #c4c4c4;
    background-color: #c4c4c4;
}
.hide-scrollbar::-webkit-scrollbar,
.hide-scrollbar::-webkit-scrollbar-track,
.hide-scrollbar::-webkit-scrollbar-thumb,
.hide-scrollbar::-webkit-scrollbar-button {
    display: none !important;
}

/* ??? - no reconozco donde se utilizan */
.is-search {
    font-weight: 500 !important;
    color: var(--gray) !important;
    padding-left: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false' data-prefix='fas' data-icon='search' class='svg-inline--fa fa-search fa-w-16' role='img' viewBox='0 0 512 512'%3E%3Cpath fill='gray' d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: left calc(0.375em + 0.1875rem) center !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}
.panels {
    background-color: #f0f0f0 !important;
    border-bottom: 0.063rem solid #cccccc !important;
    color: var(--text-color-base) !important;
}
.tabs {
    color: var(--base-contrast) !important;
}
.tabs-active {
    border-bottom: 0.188rem solid var(--base-contrast) !important;
    font-weight: bolder !important;
}

/* Botones del tipo "Mostrar Todo" */
.class-button-2 {
    @extend .class-button;
    background-color: transparent !important;
    border: solid 0.031rem var(--border-class-button) !important;
    a {
        color: var(--text-color-base);
        text-decoration: none;
    }
}
.class-button-2:hover {
    color: var(--btn-color-hover) !important;
    border-color: var(--border-form-control) !important;
    background-color: var(--bg-class-button) !important;
    a {
        color: var(--btn-color-hover) !important;
    }
}
// Elimina el border focus en los btn
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
    --bs-btn-border-width: none !important;
    box-shadow: none !important;
}

.btn-outline-danger {
    color: var(--color-text-danger) !important;
    border-color: var(--color-text-danger) !important;

    &:hover {
        color: var(--color-danger-btn-hover) !important;
        background-color: var(--bg-danger-btn-hover) !important;
        border-color: var(--border-danger-btn-hover) !important;
    }
}

.btn-outline-success {
    color: var(--color-text-success) !important;
    border-color: var(--color-text-success) !important;

    &:hover {
        color: var(--color-success-btn-hover) !important;
        background-color: var(--bg-success-btn-hover) !important;
        border-color: var(--border-success-btn-hover) !important;
    }
}

.text-danger-v2 {
    color: var(--color-text-danger) !important;
}
.text-success-v2 {
    color: var(--color-text-success) !important;
}

.btn-success {
    color: #fff !important;
    background-color: var(--color-text-success) !important;
    border-color: var(--color-text-success) !important;
}

.btn-danger {
    color: #fff !important;
    background-color: var(--color-text-danger) !important;
    border-color: var(--color-text-danger) !important;
}
.form-switch .form-check-input {
    width: 1.75rem !important;
    height: 1rem !important;
    cursor: pointer;
    &:checked {
        background-color: #223675 !important;
        border-color: #223675 !important;
    }
}
.form-switch .form-check-label {
    vertical-align: middle;
    margin-left: 0.5rem;
}

// Tooltips
.bg-tooltip-primary {
    background-color: var(--bg-color-primary) !important;
}

.bg-tooltip-dark {
    background-color: #000f25;
}
// Buttons Overrides
.btn.disabled {
    border: none !important;
    &:disabled {
        border: none !important;
    }
}
.btn:active:focus,
.btn:focus,
.btn:active {
    --bs-btn-border-width: var(--bs-border-width) !important;
}
