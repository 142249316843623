/* Themes */
body {
    --footer-height: 103px;
    --header-height: 60px;
    background-color: var(--background-color) !important;
    color: #353535;
    --base: #ffffff;
    --base-light: #ffffff;
    --base-white: #f7f7f7;
    --base-contrast: #000000;
    --text-color-default: #272727;
    --text-color-base: #4a4a4a;
    --bg-like-button: #ffffff;
    --text-color-base-alt-dark: #4a4a4a;
    --text-color-primary: #223675;
    --text-color-primary-alt-dark: #223675;
    --text-color-primary-alt: #223675;
    --text-color-alternative: #4f4f4f;
    --text-color-muted: #6c757d;
    --text-color-logo: #223675;
    --text-color-logo-secondary: #223675;
    --text-color-menu: #6171a2;
    --text-color-menu-active: #55a7f3;
    --text-color-menu-tooltip: #223675;
    --text-color-error: #ff565b;
    --text-color-dark-brown: #4a4a4a;
    --text-color-dark-red: #d0021b;
    --text-color-light-green: #3ba98c;
    --text-color-light-red: #dd0000;
    --text-color-placeholder: #979797;
    --text-color-placeholder2: #272727;
    --text-color-light-gray: #cccccc;
    --text-color-list: #787878;
    --bg-color-base: #f7f7f7;
    --lightblue-bg: #eff6fc;
    --bg-color-default: #ffffff;
    --bg-color-primary: #223675;
    --bg-color-medium: #e8e8e8;
    --bg-color-medium-dark: #e8e8e8;
    --bg-color-select: #223675;
    --bg-color-border: #e8e8e8;
    --bg-color-tabla-header: #dce1eb;
    --bg-color-tabla-header-2: #f1f7fd;
    --bg-color-tabla-header-3: #e7eff7;
    --bg-color-tabla-column-1: #eceef4;
    --bg-color-tabla-column-2: #f4f5f6;
    --bg-color-light-gray: #f4f5f8;
    --bg-color-light-gray-2: #f4f5f8;
    --bg-color-light-gray-3: #c7c7c7;
    --bg-color-placeholder: #6071a217;
    --bg-color-card-preview: #fff;
    --bg-color-card-icon: #0000001a;
    --bg-color-list: #f3f3f3;
    --width-form-auth: 500px;
    --accent-0: #efefef;
    --accent-1: rgba(34, 54, 117, 0.04);
    --accent-2: #d3d6e5;
    --accent-3: #bfc4d8;
    --accent-4: #adb3cd;
    --accent-5: #4e5e90;
    --accent-6: #4e5e90;
    --bg-color-error: #ff565b;
    --blue-1: #005a8e;
    --blue-2: #025d93;
    --background-color: #f3f9fc;
    --swiper-navigation-size: 20px;
    --swiper-theme-color: #223675;
    --nav-chevron-color: #233773;
    --tabs-bg-color: #f4f5f8;
    --tabs-bg-color-hover: #e3e6ee;
    --tabs-selected-tab-border: #6171a2;
    --tabs-border-bottom: #d8d8d8;
    --header-bg-color: #223675;
    --menu-bg-color: #ffffff;
    --menu-bg-color-active: #f3f9fc;
    --menu-bg-color-hover: rgba(85, 167, 243, 0.12);
    --button-outline-color: #223675;
    --background-light-color: #f5f5f5;
    --input-disabled: #4a4a4a;
    --search-element-hover: lightgray;
    --unread-notification-bg-color: #f0f1f5;
    --card-border-color: rgba(0, 0, 0, 0.125);
    --tooltip-arrow-color: #ffffff;
    --balanz-info-bg-color: #d1ecf1;
    --balanz-info-border-color: #bee5eb;
    --balanz-info-color: #0c5460;
    --border-light-gray: #ccc;
    --border-dark-gray: #979797;
    --bg-light-cotizaciones: #6170a20f;
    --input-bg-watchlist: rgba(208, 223, 231, 0.2);
    --bg-box-compra: rgba(59, 169, 140, 0.2);
    --bg-box-venta: #f6ccd1;
    --bg-box-cambio-fondo: rgba(251, 151, 71, 0.2);
    --text-box-cambio-fondo: #de7a2b;
    --text-box-compra: #3ba98c;
    --text-box-venta: #d0021b;
    --bg-color-primary-hover: #011e48;
    --text-hover-writable-select: #e3e6ee4d;
    --bg-light-alert: #fdfdfd;
    --text-color-balanz-card: #4a4a4a;
    --bg-alert-cambiar-password: #d1ecf1;
    --border-cambiar-password: none;
    --border-color-usermenu: #cccccc;
    --border-light-blue: #bbc2d7;
    --bg-color-checkbox-span: #cccccc;
    --yellow-alert-dark: #efaa5a;
    --bg-generic-search-item-hover: rgba(227, 230, 238, 0.3);
    --text-info-primary: #223675;
    --bg-button-primary: #223675;
    --bg-button-secondary-hover: #e3e6ee;
    --text-button-primary: #ffffff;
    --bg-hover-button-primary: #011e48;
    --text-color-readonly-input: #4a4a4a;
    --border-class-button: #cccccc;
    --bg-class-button: #ffffff;
    --border-bottom-color: #d8d8d8;
    --border-bottom-color-2: #d8d8d8;
    --border-bottom-color-3: #c8c8c8;
    --border-color: #d8d8d8;
    --border-badge-notification: #223675;
    --bg-input-currency: #eff6fc;
    --bg-codigo-4d: #eff6fc;
    --color-text-label: #4a4a4a;
    --color-gray-input: #5e5e5e;
    --color-gray-input-2: #223675;
    --color-alert-info: #0c5460;
    --bg-alert-info: #d1ecf1;
    --border-alert-ifno: #bee5eb;
    --color-alert-warning: #8a6a09;
    --bg-alert-warning: #fff3cd;
    --border-alert-warning: #ffeeba;
    --border-form-control: #ccc;
    --high-contrast-black: #000000;
    --bg-confirm-order: transparent;
    --border-color-card: #e8e8e8;
    --color-text-success: #3ba98c;
    --color-text-danger: #d20d25;
    --bg-tooltip: #223675;
    --color-exchange-lower: #dd0000;
    --text-recomendaciones-instrument: #4a4a4a;
    --color-swiper-recomendaciones-button: #c4c4c4;
    --bg-buttons-date-range: #f7f7f7;
    --color-buttons-date-range: #c7c7c7;
    --color-modal-deposit: #6c6c6c;
    --text-button-tertiary-2: #6171a2;
    --text-color-secondary: #6171a2;
    --bg-mensaje-sin-puntas-compra: rgba(59, 169, 140, 0.05);
    --bg-mensaje-sin-puntas-venta: rgba(208, 2, 27, 0.05);
    --tooltip-info-bg: #f0f1f5;
    --login-disabled-bg: #e8e8e8;
    --all-white: #ffffff;
    --color-alert-danger: #720c19;
    --bg-alert-danger: #f6cfd3;
    --border-alert-danger: #f2bbc2;
    --action-button-bg-color: #ffffff;
    --action-button-hover-bg-color: #e3e6ee;
    --bg-alert-success: #d1ecf1;
    --border-alert-success: #bee5eb;
    --bg-treemap-positive: #ebf6f3;
    --bg-treemap-negative: #fce5e5;
    --bg-treemap-neutro: #f7f7f7;
    --btn-group-active: #000000;
    --color-text-upload: #4f4f4f;
    --bg-upload: #fdfdfd;
    --border-upload: #e8e8e8;
    --bg-link: #f3f9fc;
    --color-text-popover: #223675;
    --bg-badget-info: #ffeeba;
    --bg-color-badge-new: #86a3f9;
    --text-color-red-2: #8e0000;
    --bg-color-red-2: rgba(142, 0, 0, 0.2);
    --text-color-red3: #ea3939;
    --bg-color-red3: rgba(234, 57, 57, 0.2);
    --text-color-mostaza: #c69506;
    --bg-color-mostaza: rgba(253, 214, 81, 0.2);
    --text-color-green-lima: #59b964;
    --bg-color-green-lima: rgba(89, 185, 100, 0.2);
    --text-oferta-mercados-det-instrumento: #7f7f7f;
    --bg-cyan-light: #e9f7fe;
    --text-color-blue-cyan: #0c5460;
    --watchlist-subtitle: #707070;
    --btn-actions: #c7c7c7;
    --btn-actions-bg: #ffffff;
    --bg-tooltip-clicked: #f3f3f3;
    --border-item: #c7c7c7;
    --btn-color-hover: #4a4a4a;
    --color-success-btn-hover: #3ba98c;
    --bg-success-btn-hover: #3ba98c33;
    --border-success-btn-hover: #3ba98c;
    --color-danger-btn-hover: #d20d25;
    --bg-danger-btn-hover: #d20d2533;
    --border-danger-btn-hover: #d20d25;
    --bs-border-color: #e8e8e8;
    --success: #3ba98c;
    --text-color-card-precio-diario: #000000;
    --text-color-max-min-precio-diario: #7a7a7a;
    --text-color-max-min-data-precio-diario: #4a4a4a;
    --text-alert-precio-diario-green: #097c6f;
    --text-alert-precio-diario-red: #a10000;
    --indicator-background: #9fabc9;
    --text-color-precio-disponible: #272727;
}

body.dark-theme {
    background-color: var(--background-color) !important;
    color: #dfdfdf;
    --base: #000000;
    --base-light: #c7c7c7;
    --base-white: #f7f7f7;
    --base-contrast: #ffffff;
    --text-color-default: #f7f7f7;
    --text-color-base: #c7c7c7;
    --text-color-base-alt-dark: #9fabc9;
    --text-color-primary-alt: #ffffff;
    --text-color-primary: #c7c7c7;
    --text-color-primary-alt-dark: #9fabc9;
    --text-color-alternative: #c7c7c7;
    --text-color-muted: #c7c7c7;
    --text-color-logo: #223675;
    --text-color-logo-secondary: #e8e8e8;
    --text-color-menu: #9fabc9;
    --text-color-menu-active: #f7f7f7;
    --text-color-menu-tooltip: #000000;
    --text-color-error: #ff565b;
    --text-color-dark-brown: #f7f7f7;
    --text-color-dark-red: #d0021b;
    --text-color-light-green: #3ba98c;
    --text-color-light-red: #dd0000;
    --text-color-placeholder: #979797;
    --text-color-placeholder2: #c7c7c7;
    --text-color-light-gray: #cccccc;
    --text-color-list: #787878;
    --bg-color-base: #323844;
    --lightblue-bg: #1b1d27;
    --bg-color-default: #1b1d27;
    --bg-color-primary: #20242c;
    --bg-color-medium: #474b55;
    --bg-color-medium-dark: #3b3b3b;
    --bg-color-select: #223675;
    --bg-color-border: #e8e8e8;
    --bg-color-tabla-header: #20242c;
    --bg-color-tabla-column-1: #343947;
    --bg-color-tabla-column-2: #2b2f3a;
    --bg-color-light-gray: #323844;
    --bg-color-light-gray-2: #323844;
    --bg-color-light-gray-3: #c7c7c7;
    --bg-color-placeholder: #6071a217;
    --bg-color-card-preview: #272b35;
    --bg-color-card-icon: #323844;
    --bg-color-list: #f3f3f3;
    --width-form-auth: 500px;
    --accent-0: #4e5261;
    --accent-1: #3a3e49;
    --accent-2: #2d3038;
    --accent-3: #272930;
    --accent-4: #212329;
    --accent-5: #4e5e90;
    --accent-6: #c7c7c7;
    --gray: #efefef;
    --bg-color-error: #ff565b;
    --blue-1: #005a8e;
    --blue-2: #025d93;
    --background-color: #303641;
    --swiper-navigation-size: 20px;
    --swiper-theme-color: #223675;
    --tabs-bg-color: #20242f;
    --tabs-bg-color-hover: #292e3f;
    --tabs-selected-tab-border: #6170a2;
    --tabs-border-bottom: #5a5f67;
    --header-bg-color: #000000;
    --menu-bg-color: #1b1d27;
    --menu-bg-color-active: rgba(0, 0, 0, 0.45);
    --menu-bg-color-hover: #353841;
    --button-outline-color: #c7c7c7;
    --background-light-color: #1b1d27;
    --input-disabled: #f7f7f7;
    --search-element-hover: rgba(0, 0, 0, 0.12);
    --unread-notification-bg-color: rgba(0, 0, 0, 0.45);
    --card-border-color: rgba(255, 255, 255, 0.125);
    --tooltip-arrow-color: #1b1d27;
    --balanz-info-bg-color: #272b35;
    --balanz-info-border-color: #bee5eb;
    --balanz-info-color: #3ba98c;
    --border-light-gray: #ccc;
    --border-dark-gray: #979797;
    --bg-light-cotizaciones: #6170a20f;
    --input-bg-watchlist: rgba(208, 223, 231, 0.2);
    --bg-color-primary-hover: #011e48;
    --text-hover-writable-select: #e3e6ee4d;
    --bg-light-alert: transparent;
    --text-color-balanz-card: #c7c7c7;
    --bg-alert-cambiar-password: #272b35;
    --border-cambiar-password: #3ba98c;
    --border-color-usermenu: #5a5f67;
    --border-light-blue: #bbc2d7;
    --bg-color-checkbox-span: #323844;
    --yellow-alert-dark: #efaa5a;
    --bg-generic-search-item-hover: #272b35;
    --text-info-primary: #9fabc9;
    --bg-button-primary: #6171a2;
    --bg-button-secondary-hover: rgba(97, 113, 162, 0.2);
    --text-button-primary: #c7c7c7;
    --bg-hover-button-primary: #465070;
    --text-color-readonly-input: #9fabc9;
    --bg-box-compra: rgba(0, 199, 148, 0.2);
    --bg-box-venta: rgba(255, 86, 92, 0.2);
    --text-box-venta: #ff565c;
    --border-class-button: #c7c7c7;
    --bg-class-button: #272b35;
    --border-bottom-color: #5a5f67;
    --border-bottom-color-2: #979797;
    --border-bottom-color-3: #979797;
    --border-color: #979797;
    --border-badge-notification: #000000;
    --bg-input-currency: #000000;
    --bg-codigo-4d: #272b35;
    --color-text-label: #9fabc9;
    --color-gray-input: #979797;
    --color-gray-input-2: #979797;
    --color-alert-info: #dbedf9;
    --bg-alert-info: #454b56;
    --border-alert-ifno: transparent;
    --color-alert-warning: #f9903d;
    --bg-alert-warning: #493e3a;
    --border-alert-warning: transparent;
    --border-form-control: #979797;
    --high-contrast-black: #c7c7c7;
    --bg-confirm-order: #3f4452;
    --text-box-compra: #00c794;
    --border-color-card: #5a5f67;
    --color-text-success: #00c794;
    --color-text-danger: #ff565c;
    --bg-tooltip: #000f25;
    --color-exchange-lower: #ff565c;
    --text-recomendaciones-instrument: #9fabc9;
    --color-swiper-recomendaciones-button: #c4c4c480;
    --bg-buttons-date-range: #313640;
    --bg-like-button: #313640;
    --color-buttons-date-range: #c7c7c7;
    --color-modal-deposit: #c7c7c7;
    --text-button-tertiary-2: #f4f5f8;
    --text-color-secondary: #f4f5f8;
    --bg-mensaje-sin-puntas-compra: rgba(59, 169, 140, 0.05);
    --bg-mensaje-sin-puntas-venta: rgba(208, 2, 27, 0.05);
    --tooltip-info-bg: #1b1d27;
    --login-disabled-bg: #000000;
    --all-white: #ffffff;
    --color-alert-danger: #ff5f64;
    --bg-alert-danger: #3f343d;
    --border-alert-danger: #3f343d;
    --action-button-bg-color: #272b35;
    --action-button-hover-bg-color: #1b1d27;
    --bg-alert-success: #2c3b41;
    --border-alert-success: #2c3b41;
    --bg-treemap-positive: #233b3f;
    --bg-treemap-negative: #3d2f39;
    --bg-treemap-neutro: #323844;
    --btn-group-active: #f7f7f7;
    --color-text-upload: #9fabc9;
    --bg-upload: #323844;
    --border-upload: transparent;
    --bg-link: #323844;
    --color-text-popover: #f4f5f8;
    --bg-badget-info: #ffeeba33;
    --bg-color-badge-new: #86a3f9;
    --text-color-red-2: #fb4747;
    --bg-color-red-2: rgba(251, 71, 71, 0.2);
    --text-color-red3: #ea3939;
    --bg-color-red3: rgba(234, 57, 57, 0.2);
    --text-color-mostaza: #c69506;
    --bg-color-mostaza: rgba(253, 214, 81, 0.2);
    --text-color-green-lima: #59b964;
    --bg-color-green-lima: rgba(89, 185, 100, 0.2);
    --text-oferta-mercados-det-instrumento: #7f7f7f;
    --bg-cyan-light: #374155;
    --text-color-blue-cyan: #92c4ea;
    --watchlist-subtitle: #c7c7c7;
    --btn-actions: #c7c7c7c7;
    --btn-actions-bg: #000000;
    --bg-tooltip-clicked: #313640;
    --border-item: #5a5f67;
    --btn-color-hover: #ffffff;
    --color-success-btn-hover: #3ba98c;
    --bg-success-btn-hover: #3ba98c33;
    --border-success-btn-hover: #3ba98c;
    --color-danger-btn-hover: #d20d25;
    --bg-danger-btn-hover: #d20d2533;
    --border-danger-btn-hover: #d20d25;
    --bs-border-color: #5a5f67;
    --success: #3ba98c;
    --danger: #d20d25;
    --text-color-card-precio-diario: #f7f7f7;
    --text-color-max-min-precio-diario: #c7c7c7;
    --text-color-max-min-data-precio-diario: #f7f7f7;
    --text-alert-precio-diario-green: #00c794;
    --text-alert-precio-diario-red: #ff565c;
    --indicator-background: #9fabc9;
    --text-color-precio-disponible: #9fabc9;
}
